.hero-title-secondary {
    position: absolute;
    top: 61%;
    left: 50%;
    transform: translate(-50%,-50%);
}

@media screen and (max-width: 576px) {
    .hero-title-secondary {
        font-size: 2rem;
    }
}

.category-button-wrapper {
  max-width: 950px;
  margin: 1rem auto 4rem;
}

.button-henrico-category {
  border: 1px solid #979797 !important;
  color: #04368B;
}

.button-column {
    display: flex;
    margin-top: 1rem;
 }

a.button-primary.button-henrico-category {
  padding: 15px 20px;
  line-height: 1.2;
}

.button-column.col-lg-3,
.button-column.col-lg-4 {
  justify-content: center;
}

.button-row.button-row-1.row {
  max-width: 900px;
  margin: 0 auto;
  white-space: nowrap;
  text-align: center;
}

.data-card-body {
    margin: 0 1rem;
}

.button-row {
    margin-top: .5rem;
}

.button-row.second-button-row.row {
    max-width: 660px;
    margin: 0 auto;
}

.why-henrico-map {
    margin: 0 auto;
    max-width: 100%;
}

@media screen and (max-width: 768px) {
    .category-button-wrapper {
        max-width: 450px;
    }
}

@media screen and (max-width: 575px) {
    .button-column { 
        margin-top: .5rem;
    }
}

.button-let-us {
    background: #6ABE29 0% 0% no-repeat padding-box;
    color: white;
}

.row-let-us-help {
    text-align: center;
    margin-bottom: 4rem;
}

.henrico-boxText{
  top: 284px;
  left: 97px;
  height: 19px;
  text-align: center;
  letter-spacing: -0.33px;
  color: #04368B;
  opacity: 1;
}

.why-henrico-category {
  text-align: center;
}

.cyanShadow1{
  box-shadow: inset 0px 40px 0px -25px #5CB4AA;
}

.greenShadow1{
  box-shadow: inset 0px 40px 0px -25px #6EBC4C;
}

.blueShadow1{
  box-shadow: inset 0px 40px 0px -25px #66A7CF;
}

.orangeShadow1{
  box-shadow: inset 0px 40px 0px -25px #E68F33;
}

.lightgreen1{
  box-shadow: inset 0px 40px 0px -25px #5CB4AA;
}

.news-box{
  font-weight: 900;
//   background-color: #E6E6E6!important;
}

.need-Help-text {
  top: 1456px;
  left: 0px;
  width: 1024px;
  height: 45px;
  text-align: center;
  letter-spacing: -0.26px;
  color: #000000;
  opacity: 1;
  font-size: 13px;
}

.henrico-image{
  top: 978px;
  left: 523px;
  width: 474px;
  height: 440px;
  background: transparent url('images/Stragtegically-Located-Map.png') 0% 0% no-repeat padding-box;
  opacity: 1;
}

.modal-content {
    max-width: 500px;
    height: 500px;
    margin: 0 auto;
}

.modal-body {
    padding: 2rem !important;
    background-color: #054496;
}

button.button-primary.button-modal.btn.btn-primary {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: #6ABE29 !important;
    border-color: #6ABE29 !important;
    border-radius: 0 !important;
    font-size: 16px !important;
    font-weight: 700 !important;
}

 .button-row.button-row-2.row {
   max-width: 737px;
   white-space: nowrap;
   margin: 0 auto;
   text-align: center;
 }





.blueContainer {
    width: 100%;
    margin-right: 200px;
    margin-top: 40px;
    margin-bottom: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #2B72B9;
}

.formCopyContainer {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    align-items: center;
}

.formInputContainer {
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    align-items: center;
}

.formCopy {
    color: white;
    font-size: 14pt;
    text-align: center;
}

.formText {
    color: white;
}
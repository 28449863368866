.contact-us-form-row {
    width: 100%;
}

.form-check-label {
    margin-bottom: 0;
    font-weight: 100;
}

.alert-heading.h4 {
    margin-bottom: 0;
}

.button-primary {
    font-size: 16px;
    border: none;
    font-weight: 700;
    min-width: 200px;
    height: 50px;
    text-transform: uppercase;
}

.button-green {
  background-color: #6ABE29 !important;
  border: unset !important;
  border-radius: unset !important;
  color: white;
}

.form-label p {
    margin-bottom: 0;
}
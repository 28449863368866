a.link--black {
    color: black !important;
}

.archive-date-row {
    margin-top: 0;
}

.date-row-text {
    font-size: 14px;
}

.press-release-archive-image {
    margin: 0 auto;
    height: 260px;
    max-width: 100%;
    height: auto;
}

@media screen and (max-width: 576px) {
    .press-release-archive-image {
        width: 100%;
        max-width: 350px;
    }
}

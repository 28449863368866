.featured-news-title-mobile,
.featured-news-title-desktop {
    font-size: 2rem;
}

.featured-news-image {
    width: 100%;
    max-width: 262.5px;
}

.upcoming-events-title {
    font-size: 2rem;
}

span.upcoming-events-date {
    font-weight: 900;
}

@media screen and (max-width: 575px) {
    .image-fit {
        height: 250px;
        width: 330px;
    }
}

.featured-news-title-mobile {
    display: none;
    margin-bottom: 2rem;
}

@media screen and (max-width: 575px) {
    .featured-news-title-mobile {
        display: block;
    }

    .featured-news-title-desktop {
        display: none;
    }
}

.text {
    float: left;
    margin-left: 212px;
    margin-top: -191px;
}

.h4 {
    margin: 0px;

}

.breaking-section {
    margin-top: 2rem;
    font-size: 1.2rem;
}

.breaking {
    text-align: left;
    letter-spacing: -0.39px;
    color: #6EBC4C;
    font-weight: 900;
}

.blackText {
    top: 23px;
    left: 0px;
    width: 301px;
    height: 23px;
    text-align: left;
    font: Book 19px/23px Gotham;
    letter-spacing: -0.39px;
    color: #000000;
    font-size: 19px;
}

.upcoming-events-row.row {
    margin-left: 2rem;
    margin-top: 0;
}

@media screen and (max-width: 991px) {
    // .separator-vertical-line {
    //     display: none;
    // }
}

.col-upcoming-events {
    padding-left: 2rem;
}

@media screen and (max-width: 991px) {
    .col-upcoming-events {
        margin-top: 3rem;
    }

    .upcoming-events-row.row {
        margin: 0 auto;
    }

    .no-events-placeholder {
        text-align: center;
    }

    .upcoming-events-text.col-12 {
        max-width: 600px;
        margin: 0 auto;
    }
    
    h4.upcoming-events-title {
        text-align: center;
    }

    .col-upcoming-events .row {
        justify-content: center;
    }
}

.upcoming-events {
    letter-spacing: -0.61px;
    color: #000000;
    opacity: 1;
    font-size: 25px;
    font-weight: 900;
}

.upcoming-events-text {
    font-size: 1.05rem;
}

.statBox {
    padding: 15px;
    width: 134px;
    height: 134px;
    background: #E6E6E6 0% 0% no-repeat padding-box;
    opacity: 1;

}

.boxtext {
    top: 847px;
    left: 533px;
    width: 191px;
    height: 42px;
    text-align: left;
    letter-spacing: 0;
    color: #04368B;
    opacity: 1;
}

.companiesBanner {
    width: 100%;
    height: 100%;
    margin-top: 30px;
    text-align: center;
    background-color: #E2E3E4;
    padding-top: 3rem;
}

.companiesBannerTitle {
    color: #04368B;
    font-size: 30px;
}

.companyLogos {
    width: 100%;
    height: 100%;
}

.statTitle {
    font-size: 30px;
    color: #04368b;
    font-weight: 900;
}

.statDescription {
    font-size: 14px;
}

.row {
    margin-top: 20px;
}

.card {
    max-width: 100%;
    height: auto;
    min-height: 200px;
}

.breaking-news-link-copy {
    color: black !important;
    font-weight: 100 !important;
}

.featured-news-border {
    border-right: 1px solid black;
}

@media screen and (max-width: 991px) {
    .featured-news-border {
        border-right: unset;
    }
}

.featured-video {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .featured-video-text {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 30px!important;
  }

  .featured-video-text2 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
  }  
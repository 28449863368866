.card-row.am-cards.row {
    justify-content: space-evenly;
}

.card-row.am-cards-small.row {
    justify-content: space-between;
    display: none;
}

@media screen and (max-width: 768px) {
    .card-row.am-cards.row {
        justify-content: space-between;
        display: none;
    }

    .card-row.am-cards-small.row {
        justify-content: space-between;
        display: flex;
    }
}

.quote-copy{
    font-size: 1.5rem !important;
}

/*.quote-signature{
    font-size: 1.25rem !important;
}*/

.quote-container{
    margin-top: 5.5rem !important;
}

// NEW CSS

.accordion-wrapper {
    border: 0px;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  
  .accordion-title {
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #0073CF;
    color: #fff;
    font-weight: 600;
  }
  
  .accordion-content {
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    background-color: white;
  }
  
  .property-item {
    border: 1px solid #eeeeee;
    padding: 10px;
    border-radius: 4px;
  }

  .property-image {
    width: 100%;
    height: auto;
    padding-bottom: 8px;
  }

  .property-title {
    font-size: 18px;
    font-weight: 600;
  }
  

  @media (max-width: 768px) { /* Adjust the max-width as needed for your design */
    .accordion-content {
      grid-template-columns: 1fr; /* Collapse to a single column on mobile devices */
    }
  }
.event-date {
    margin-bottom: 0;
}

.event-time {
    margin-bottom: 0;
}

.card-event-date {
    background-color: #F6F6F6;
    text-align: center;
    padding-left: 5px;
    max-width: 75px;
    margin: 0 auto;
}

@media screen and (max-width: 576px) {
    .card-event-date {
        margin: 0 auto 1rem;
    }
}

.card-event-month {
    margin-bottom: 0;
}

.card-event-day {
    margin-bottom: 0;
}

.card-event-start-time {
    margin-bottom: 0;
    font-size: .6rem;
    border-top: 1px solid #C4C4C4;
}
.card-row.scm.row {
    justify-content: space-between;
}

.logo-space {
    margin-top: 50px;
    margin-bottom: 50px;
}
.video-container {
    max-width: 700px;
    margin: 20px auto;
}

.video-iframe {
    display: block;
    width: 100%;
}

@media (max-width: 768px) {
    .video-container {
        max-width: 100%;
    }
}
  
.primary-image {
  width: 100%;
}

.row-intro {
  margin-bottom: 0.5rem;
}

.row-members {
  margin-top: 3rem;
}

.row-individual-member {
  margin-bottom: 3rem;
}

.member-title {
  font-weight: 900;
  margin-bottom: 0;
}

.member-expertise-title {
  font-weight: 900;
}

.member-description {
  white-space: pre-wrap;
}

.page-title {
  margin-bottom: 0.5rem !important;
}

.pull-right {
  p {
    > strong {
      color: #054496;
    }
  }
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
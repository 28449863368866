.hero {
    position: relative;
}

.page-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
    font-weight: 700;
}

.hero-title {
    color: #FFFFFF;
    font-size: 4rem;
    font-weight: 200;
    letter-spacing: -0.97px;
    text-align: center;
    text-shadow: 10px 10px 15px #000000;
    // text-transform: uppercase;
}

.hero-title2 {
    color: #FFFFFF;
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: 0;
    text-align: center;
    text-shadow: 10px 10px 15px #0000008b;
    max-width: 420px;
    padding-bottom: 30px;
}

.hero-caption {
    position: absolute;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, .45);
    color: white;
    text-transform: uppercase;
    padding: 1rem 3rem;
}

.hero img {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.henricoTomorrowLogo {
    width: 340px;
}

.orange {
    background-color: #E98300!important;
}

@media screen and (max-width: 442px) {
    .hero-title2 {
        font-size: 1.2rem!important;
        padding-bottom: 0px;
    }
    .henricoTomorrowLogo {
        width: 300px;
    }
}


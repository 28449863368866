.propnow-archive-content-wrapper {
    display: flex;
    flex-direction: row;
}

.propnow-archive-content-col1 {
    width: 50%;
    max-width: 50%;
}

.propnow-archive-content-col2 {
    width: 50%;
    max-width: 50%;
}
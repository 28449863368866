.alert-banner {
    background-color: #0073CF;
    color: white;
    padding: 1rem 2rem;
    width: 100%;
    z-index: 2;
    display: flex;
    justify-content: center;
}

.alert-banner-text {
    max-width: 1176px;
    margin: 0px auto;
    text-align: center;
}

.alert-banner-bold {
    font-weight: 700;
}
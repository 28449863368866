.ExploreLogoWrapper {
    display: flex;
    flex-direction: row;
    padding: 12px;
    gap: 20px;
    align-items: center;
}

.ExploreLogo1 {
    width: 146px;
    height: 98px;
}

.ExploreLogo2 {
    width: 120px;
    height: 120px;
}

@media screen and (max-width: 480px) {
    .ExploreLogo1 {
        width: 96px;
        height: 64px;
    }
    .ExploreLogo2 {
        width: 80px;
        height: 80px;
    }
  }
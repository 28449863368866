.featured-image {
    width: 100%;
}

.title {
    text-align: center;
}

.date {
    text-align: center;
}

.single-date-row {
    margin-top: 0;
}

.property-resources {
  h2 { margin-bottom: 0.75rem; }
}

li.resource-list-item {
    list-style-type: none;
}
.subscribe-newsletter-henrico-now {
    margin-top: 2rem;
    text-align: center;
}

.henrico-now-newsletter-input {
    margin-top: 1rem;
    background-color: #D7D7D7;
    border: none;
}

p.form-label-text {
    margin-bottom: -1rem;
    padding-bottom: 0;
}

@media screen and (max-width: 768px) {
    .subscribe-newsletter-home {
        position: relative;
        margin: 1rem auto;
    }
}

.buttonContainer {
    margin-left: auto;
    margin-right: auto;
    width: 25%;
    align-items: center;
    padding-bottom: 10px;
}
.finance-insurance-logos {
  margin-top: 5px;
}

.centered-body {
  text-align: center;
  font-size: 20px;
}

.centered-body2 {
  text-align: center;
  font-size: 24px;
}


body {
  font-family: 'Montserrat' !important;

  .single-copy-row {
    b {
      font-weight: bold;
    }
  }

  p > strong {
    color: #04368B;
    font-size: 1.2rem;
    font-weight: 800 !important;
    margin-bottom: 0;
  }

  .assetContainer {
    img {
      width: 75%;
      height: auto;
    }
  }
}

// .col img {
//     width: 100%;
// }

.image-row {
    margin: 4rem 0 2rem;
}

.image-row-secondary {
    margin-top: 3rem;
}

// div.col{
//     padding: 0 5px;
// }

.retention-card-row {
    justify-content: center;
}

.retention-cards {
    margin-bottom: 1rem;
}

@media screen and (min-width: 769px) and (max-width: 991px) {
    .retention-card-row.row {
        max-width: 600px;
        margin: 0 auto;
        justify-content: flex-start;
    }
}

@media screen and (max-width: 768px) {
    .retention-card-row.row {
        max-width: 400px;
        margin: 0 auto;
        justify-content: flex-start;
    }
}

.gallery_item {
    object-fit: cover;
    width: auto;
    padding: 0;
    padding-top: 4px;
    padding-bottom: 4px;
}
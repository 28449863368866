.card-row.am-cards.row {
    justify-content: space-evenly;
}

.card-row.am-cards-small.row {
    justify-content: space-between;
    display: none;
}

@media screen and (max-width: 768px) {
    .card-row.am-cards.row {
        justify-content: space-between;
        display: none;
    }

    .card-row.am-cards-small.row {
        justify-content: space-between;
        display: flex;
    }
}

.quote-copy{
    font-size: 1.5rem !important;
}

/*.quote-signature{
    font-size: 1.25rem !important;
}*/

.quote-container{
    margin-top: 5.5rem !important;
}

.featured-video {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
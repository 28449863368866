$footerBackground: #65b1e1;
$footerTextColor: #eff4f8;
$footerTextBorder: #979797;
$footerFont: Gotham, book;

.footer {
    background-color: $footerBackground;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.copyright {
    text-align: left;
}

.footer-text {
    color: $footerTextColor;
    text-shadow: 1px $footerTextBorder;
}

.dot {
    height: 10px;
    width: 10px;
    margin-left: 1rem;
    margin-right: 1rem;
    background-color: $footerTextColor;
    border-radius: 50%;
    display: inline-block;
  }

.footer-text > .copyright {
    font-weight: 100;
    font-size: .8rem;
}

@media screen and (max-width: 768px) {
    .hide-on-mobile{
        display: none;
    }
}
@media screen and (min-width: 769px) {
    .hide-on-lg{
        display: none;
    }
}

a.footer-tel {
    color: #FFFFFF !important;
    text-decoration: underline;
}

a.footer-privacy {
    color: #FFFFFF !important;
    text-decoration: underline;
}

.footer-address {
    font-weight: 700;
}

.row.news-card-row {
    margin: 0 auto;
}

.news-card-description {
    //font-size: .8rem;
    //font-weight: 700;
    //padding-top: .5rem;
    //max-width: 200px;
    //width: 100%;
    text-align: left;
    margin-top: 20px;
}

.news-card-titile {
    margin-top: 20px;
    font-size: 14px;
}

.news-card-image {
    max-width: 100%;
    width: 100%;
    height: auto;
}

.news-card-container {
    max-width: 100%;
}

@media screen and (max-width: 768px) {
    .news-card-container {
        margin-bottom: 2rem;
    }
}

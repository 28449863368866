.youtube-video-row.row div {
    margin: 0 auto;
    max-width: 100%;
}

.youtube-title-row.row {
    margin-top: 0;
}

.youtube-video-title {
    margin: 0 auto;
    max-width: 350px;
    text-align: center;
    font-weight: 700;
}

.video-container {
    margin: 2rem auto;
}

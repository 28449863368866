.wrapper-contact-henrico {
    margin: 4rem auto;
    padding: 0 1rem;
}

@media screen and (max-width: 575px) {
    .wrapper-contact-henrico {
        margin: 2rem auto;
    }
}

.col-contact-submit.col {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.contact-henrico-text {
     letter-spacing: -0.61px;
     color: #000000;
     opacity: 1;
     font-size: 30px;
     text-align: center;
     font-weight: 500;
}

@media screen and (max-width: 575px) {
    .contact-henrico-text {
        max-width: 200px;
        margin: 0 auto;
    }

    .col-contact-submit.col {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
}
 
.sign-up-title {
    text-align: left;
    color: #253D87;
    font-weight: 900;
    margin-bottom: .5rem;
}
 
.info-henrico{
    max-width: 275px;
    text-align: center;
    letter-spacing: -0.37px;
    color: #253D87;
    opacity: 1;
    margin: 0 auto;
}

@media screen and (max-width: 575px) {
    .info-henrico {
        text-align: center;
        margin: 0 auto;
    }
}
 
.input-email { 
    width: 322px;
    height: 27px;
    background: #FFFFFF8F;
    border: 1px solid #979797;
    font-size: .7rem;
    padding-left: .5rem;
}
 
.submit-button {
    width: 187px;
    height: 43px;
    background: #6ABE29 0% 0% no-repeat padding-box;
    opacity: 1;
    color: #FFFFFF;
}

#henricoNowCheckbox,
#propertyNowCheckbox {
    margin-right: .25rem;
}

.subscribe-newsletter-home {
    position: absolute;
    bottom: 0;
}
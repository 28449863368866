.nap-main-headline{
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
    font-size: 4rem !important;
}

.charts-title{
    font-weight: 900;
    font-size: 1.15rem;
    color: #274085;
}

.charts-title-subheading{
    font-size: .875rem;
    color: #888787;
}

.alert-banner-home {
    position: absolute;
    top: 5.7rem;
}

@media screen and (min-width: 577px) and (max-width: 1199px) {
    .alert-banner-home {
        position: absolute;
        top: 4rem;
    }
}

@media screen and (max-width: 576px) {
    .alert-banner.alert-banner-home {
        top: 3.5rem;
    }
}

.btn-charts{
    background-color: #f6f6f6 !important;
    color: #888787 !important;
    font-weight: 900 !important;
    width: 75% !important;
}

.tidbit{
    font-weight: 900 !important;
    font-size: 1.15rem !important;
    color: #888787 !important;
}

.overlay-text {
    width: 200px;
    height: 200px;
    position: absolute;
    top: 80px;   /* chartArea top */
    left: 95px; /* chartArea left */
}

.overlay-miles {
    width: 200px;
    height: 200px;
    position: absolute;
    top: 65px;   /* chartArea top */
    left: 80px; /* chartArea left */
    color: #dc883d;
    font-weight: 900 !important;
    font-size: 1.6rem !important;
}

@media only screen and (max-width: 376px) {
    .overlay-miles {
        width: 200px;
        height: 200px;
        position: absolute;
        top: 65px;   /* chartArea top */
        left: 90px; /* chartArea left */
        color: #dc883d;
        font-weight: 900 !important;
        font-size: 1.6rem !important;
    }
}

@media only screen and (min-width: 376px) and (max-width: 415px) {
    .overlay-miles {
        width: 200px;
        height: 200px;
        position: absolute;
        top: 65px;   /* chartArea top */
        left: 110px; /* chartArea left */
        color: #dc883d;
        font-weight: 900 !important;
        font-size: 1.6rem !important;
    }
}

@media only screen and (min-width: 415px) and (max-width: 1024px) {
    .overlay-miles {
        width: 200px;
        height: 200px;
        position: absolute;
        top: 65px;   /* chartArea top */
        left: 45px; /* chartArea left */
        color: #dc883d;
        font-weight: 900 !important;
        font-size: 1.6rem !important;
    }
}

@media screen and (max-width: 575px) {
    .nap-main-headline {
        font-size: 2.75rem !important;
    }
    .owners-align{
        padding-top: 0 !important;
        padding-bottom: 1rem !important;
    }
    // .hide-smaller{
    //     display: none !important;
    // }
    // .hide-large{
    //     display: none !important;
    // }
}

.overlay-style{
    color: #dc883d;
    font-weight: 900 !important;
    font-size: 2rem !important;
}



.overlay-tbs{
    color: #000000;
    font-weight: 900 !important;
    font-size: 1rem !important;
}

.owners-align{
    font-size: 1.25rem !important;
    padding-top: 4.5rem;
    padding-left: 0 !important;
}

.runway-align{
    font-size: 1.25rem !important;
    padding-top: 4rem;
}

hr {
    margin-left: 0;
    margin-right: 0;
    color: #000000;
    height: 1px !important;
    border-top: 1px solid #000000  !important;
}

.active {
    background-color: #5ebdb7;
    color: #ffffff;
  }

  .news-title{
    /* padding-left: 3.5rem !important;
     font-weight: 900;
     font-size: 1.15rem;
     color: #274085;*/
     font-size: 30px;
     font-weight: 900;
     /*left: 390px;
     text-align: left;*/
     opacity: 1;
 }

 .news-paragraph{
     padding-left: 3.5rem !important;
 }

 .news-tiles-padding{
     padding-left: 3rem !important;
 }

 .news-tiles-pad-remove{
     padding-left: 0 !important;
     padding-right: 0 !important;
 }

 .news-tile-text{
     font-weight: 900;
     font-size: .85rem;
     padding-left: 0 !important;
     padding-top: 0 !important;
 }

 .initial-tile-back{
     background-color: #5ebdb7 !important;
     color: #FFFFFF;
     font-weight: 900;
 }

 .button-hero-home {
    background: #6ABE29 0% 0% no-repeat padding-box;
    color: white;
    border: none;
    font-weight: 700;
    position: absolute;
    top: 82%;
    left: 50%;
    transform: translate(-50%, -70%);
}

.henrico-text {
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;
    letter-spacing: 0;
    color: #626262;
    opacity: 1;
}

.hero-title-home {
    position: absolute;
    top: 51%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
}

.hero-title2-home {
    position: absolute;
    top: 69%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
}

@media screen and (min-width: 442px) and (max-width: 575px) {
    .hero-home {
        height: 750px !important;
    }
    .hero-title-home {
        width: 360px;
        top: 44%;
        font-size: 3.2rem;
    }
    .hero-title2-home {
        width: 360px;
        font-size: 1rem;
        top: 66%;
    }
    // .hero-title-home {
    //     width: 95%;
    //     font-size: 2rem;
    // }
    // .hero-title2-home {
    //     width: 95%;
    //     font-size: 2rem;
    // }
    // .hero-title-home {
    //     width: 340px;
    // }
    // .hero-title2-home {
    //     width: 340px;
    // }
}

@media screen and (min-width: 576px) and (max-width: 832px) {
    .hero-title2-home {
        width: 576px;
        font-size: 1rem;
        top: 70%;
    }
    .hero-title-home {
        width: 560px;
    }
}

@media screen and (max-width: 575px) {
    .button-hero {
        top: 75%;
        transform: translate(-50%, -50%);
    }
}

@media screen and (max-width: 441px) {
    .hero.home {
        height: 750px !important;
    }
    .hero-title-home {
        width: 310px;
        font-size: 3.4rem;
        top: 40%;
    }
    .hero-title2-home {
        width: 240px;
        font-size: 1rem;
        top: 63%;
    }
}

@media screen and (max-width: 326px) {
    // .hero-title-home {
    //     top: 65%;
    // }
    // .hero-title2-home {
    //     top: 65%;
    // }
}

.backgroundImage {
    top: 0px;
    left: -2px;
    width: 1030px;
    height: 477px;
    opacity: 1;
}

.parent {
    position: relative;
    top: 0;
    left: 0;
}

.image1 {
    position: relative;
    top: 0;
    left: 0;
}

.image2 {
    position: absolute;
    top: 245px;
    left: 120px;
    width: 80%;
    height: 200px;
}

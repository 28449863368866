.resources-downloads {
  background: #F1F1F1;
  padding: 2rem;
  margin: 6rem 0;
}

.row-downloads.row {
  margin: 0 auto;
}

@media screen and (max-width: 991px) {
    .row-download.row {
        max-width: 400px;
        margin: 0 auto;
    }
}

.row-downloads {
  max-width: 1200px;
  margin: 0 auto;
}

.downloads-title {
  text-align: center;
  margin-bottom: 2rem;
}

.row-downloads p {
  margin-bottom: .5rem;
  text-decoration: underline;
  font-weight: 600;
  line-height: 1.5;
}

// .additional-resources-icon-column.col {
//     text-align: center;
// }

.resource-download-link {
    color: black !important;
}

.internal-resources-row.row {
    max-width: 325px;
    margin: 2rem auto;
}

@media screen and (max-width: 991px) {
    .row-downloads.row {
        margin: 0 auto;
        max-width: 500px;
    }
}
.resources-downloads.row ul li {
  list-style-type: none !important;
  margin-bottom: .5rem !important;
}

.resources-downloads.row ul li a {
  color: black !important;
  text-decoration: underline !important;
}

.internal-resources-row.row ul {
  padding-left: 0;
}
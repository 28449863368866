.quote-container {
    margin-top: 7rem;
    margin-bottom: 7rem;
}

.quote-copy {
    font-size: 2rem;
    color: rgb(108, 114, 138);
    text-align: center !important;
}

.quote-signature {
    font-size: 1.5rem;
    font-weight: 900;
    text-align: center !important;
    color:#5ebdb7;
}

.quote-signatureTitle {
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center !important;
  color:#5ebdb7;
  margin-top:-20px;
}

.quote-signature-caption {
    font-weight: 700 !important;
}

body {
  .quote-signature-caption {
    p > strong {
      color: #5ebdb7;
    }
  }
}

.featured-video {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
  }

  .featured-video-text {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 80px;
  }

  .featured-video-text2 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
  }  
.intermodal-transportation {
    margin-bottom: 2rem;
}

.quotes-container-location {
    margin: 2rem 0;
}

.quotes-container-location {
    margin-top: -2rem;
    margin-bottom: -2rem;
}

.location-image {
    width: 288px;
    max-width: 100%;
}

.white-oak-location-list {
    padding-right: 4rem;
}

.location-image-column {
    text-align: right;
}

.location-image-enlarge-column {
    text-align: center;
}

@media screen and (max-width: 991px) {
    .location-image-enlarge-column {
        text-align: center;
    }
    .location-image-column {
        text-align: center;
    }
    .location-image-row {
        margin-bottom: 4rem;
    }

}
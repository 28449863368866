ul li {
  margin-bottom: 0;
  font-weight: 100;
}

a.link-light {
  color: #1D4F91 !important;
  font-weight: 100;
}

a.link-light.list-no-padding-left {
    margin-left: -20px;
}


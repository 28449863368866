.video-row {
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.video-row.row {
    text-align: center;
}

.video-center {
    text-align: center;
}

.video-center.row {
    max-width: 750px;
    margin: 2rem auto;
}

.exporting-assitance-videos-wrapper {
    max-height: 211px;
    height: 211px;
    max-width: 314px;
    margin: 0 auto;
    margin-top: 1rem;
}
.data-card-wrapper {
  display: inline-block;
  margin: 1rem auto;

  .data-card {
    background-color: #E6E6E6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    height: 250px;
    width: 260px;
    text-align: left;

    &.small {
      height: 160px;
      width: 170px;
    }
    &.large {
      height: 250px;
      width: 260px;
    }
    p {
      &:nth-of-type(1) {
        margin-bottom: 0;

        > strong {
          color: #04368B;
          font-weight: 900;
          font-size: 1.6rem;
          margin-bottom: 0.3125rem;
          line-height: 1;
        }
      }
      &:nth-of-type(2) {
        font-size: .7rem;
      }
    }
  }

  .data-card-body {
    max-width: 90%;
    margin: 0 .5rem;
  }
  .data-card-button {
    position: absolute;
    bottom: 0;
    right: 0.6rem;
    text-transform: uppercase;
    cursor: pointer;
  }
}

.card-column {
    text-align: center;
}

.card-row-three-cards.row {
    max-width: 875px;
    margin: 0 auto;
}

@media screen and (min-width: 600px) and (max-width: 991px) {
    .card-row-three-cards.row {
        max-width: 575px;
        margin: 0 auto;
        justify-content: flex-start;
    }
}

@media screen and (max-width: 599px) {
    .card-row-three-cards.row {
        max-width: 400px;
        margin: 0 auto;
        justify-content: flex-start;
    }
}

.card-row-four-cards.row {
    max-width: 1200px;
    margin: 0 auto;
}

.card-row-four-cards.row {
    max-width: 1200px;
    margin: 0 auto;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .card-row-four-cards.row {
        max-width: 850px;
        justify-content: flex-start;
    }
}
@media (min-width: 769px) and (max-width: 991px) {
    .card-row-four-cards.row {
        max-width: 600px;
    }
}

@media (min-width: 600px) and (max-width: 768px) {
    .card-row-four-cards.row {
        max-width: 600px;
    }
}

@media screen and (max-width: 599px) {
    .card-row-four-cards.row {
        max-width: 400px;
    }
}

.card-row-five-cards.row {
    max-width: 875px;
    margin: 0 auto;
}



@media screen and (max-width: 599px) {
    .data-card.large {
        height: 160px !important;
        width: 170px !important;
        margin-bottom: 0.3125rem;
        line-height: 1;
    }
    
    .data-card-wrapper .data-card {
      height: 200px !important;
      width: 170px !important;
    }

    .data-card-wrapper .data-card.large .data-card-body .card-text {
      font-size: .7rem !important;
    }

    .data-card-wrapper .data-card p:nth-of-type(1) > strong {
      color: #04368B;
      font-weight: 900;
      font-size: 1.4rem;
      margin-bottom: 0.3125rem;
      line-height: 1;
    }
    .title-line-1, .title-line-3, .title-line-2 {
        font-size: 1.2rem;
    }
}

.title-line-1,title-line-3,
.title-line-2 {
    color: #04368B;
    font-weight: 900;
    font-size: 1.6rem;
    margin-bottom: 5px;
    line-height: 1;
}

.card-text{
    font-size: .7rem;
}



.color-0073cf {
    background-color: #0073cf;
    height: 10px;
}
.color-5BBBB7 {
    background-color: #5BBBB7;
    height: 10px;
}
.color-D6276A {
    background-color: #D6276A;
    height: 10px;
}
.color-69BE28 {
    background-color: #69BE28;
    height: 10px;
}
.color-007934 {
    background-color: #007934;
    height: 10px;
}
.color-E98300 {
    background-color: #E98300;
    height: 10px;
}
.color-78207b {
    background-color: #78207b;
    height: 10px;
}
.color-66A7CF {
    background-color: #66A7CF;
    height: 10px;
}
.color-5CB4AA {
    background-color: #5CB4AA;
    height: 10px;
}



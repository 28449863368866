body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  color: #000000;
  font-weight: 100;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.container {
	max-width: 1200px !important;
}

.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/* Standardized CSS */
.button-primary {
    font-size: 16px;
    border: none;
    font-weight: 700;
    min-width: 200px;
    height: 50px;
    text-transform: uppercase;
}

.button-green {
  background-color: #6ABE29;
  color: white;
}

.heading-secondary {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.2;
}

.paragraph-header--blue {
	color: #04368B;
	font-size: 1.2rem;
	font-weight: 800;
	margin-bottom: 0;
}

.paragraph-header--black {
  color: #000000;
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 0;
}

.copy-black {
  color: #000000;
  font-weight: 800;
}

.paragraph-copy--blue {
  color: #04368B;
}

.copy-blue {
  color: #04368B;
}

.no-padding {
  margin: 0 !important;
  padding: 0 !important;
}

a {
  color: #054496 !important;
  font-weight: 700;
}

p {
  font-weight: 100;
  line-height: 1.8;
}

.no-margin {
    margin-top: 0 !important;
}

ul {
    padding-inline-start: 18px;
}

.alert-banner-home a,
.alert-banner-text a {
    color: white !important;
}

.eventImage {
  width: 100%;
  max-width: 860px;
  height: auto;
}

@media screen and (max-width: 768px) {
    .post-logo img {
        max-width: 100%;
        margin: 0 auto;
    }
}

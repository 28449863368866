.minutes-col-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.minutes-col {
    width:33%;
}

@media screen and (max-width: 480px) {
    .minutes-col {
        width:100%;
    }
    .minutes-col-wrapper {
        flex-direction: column;
    }
  }
.list-articles-col {
    margin-top: 2rem;
}

a.henrico-archive-title--black {
    color: black !important;
    // font-weight: 900;
}

.henrico-archive-date {
    margin-top: 20px;
    font-size: 14px;
}

.henrico-now-article-grid-image {
    width: 100%;
}

.henrico-now-see-more {
    margin: 0 auto;
}

.mt-10 {
    margin-top: 10px;
}

.subscribe-row.row {
    max-width: 275px;
}

.subscribe-row, .subscribe-row p {
    margin-bottom: 0 !important;
}

/* Social media embed */
.henrico-now-social-media-row {
    margin-top: 4rem;
    margin-bottom: 2rem;
}

iframe {
    border: none;
}


.feature-image {
    width: 100%;
}

.henrico-now-see-more-button-row {
    text-align: center;
    margin: 5rem auto;
}

.left-padding {
    margin-left: 2rem;
}

@media screen and (max-width: 768px) {
    .left-padding {
        margin-left: 1rem;
    }

    .left-padding-picture {
        margin-left: 1.5rem;
    }

    #subscribe-form{
        margin-left: .5rem;
    }
}

input#first-name,
input#last-name,
input#company-name,
input#subscribe-email {
    // background-color: rgba(230, 230, 230, .56);
    // border: unset;
    height: 27px;
    width: 100%;
}

div#___gcse_1 {
    margin-left: 14px;
}

.see-more-btn {
  background: #6ABE29 0% 0% no-repeat padding-box;
  color: white;
  border: none;
  font-weight: 700;
  padding: 0 20px;
}

.ul-li-light {
    font-weight: 100;
}

.covid-unordered-list li {
  margin-bottom: 1rem;
}

.covid-video-container {
  max-width: 888px;
}

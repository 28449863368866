.list-padded {
    padding-inline-start: 40px;
    padding-right: 1.5rem;
}

.card-style.active {
    background-color: #1D3D7C;
    color: #FFFFFF;
}

ul li {
    margin-bottom: 2rem;
}

.card-style {
    background-color: #D7D7D7;
    color: #8F8F8F;
    min-height: 126px;
    font-size: 23px;
    text-align: left;
    width: 207px;
    display: flex;
    align-items: center;
    padding-left: 1rem;
}

.category-card-columns {
    margin-bottom: 2rem;
}

@media screen and (min-width: 578px) and (max-width: 786px) {
    .category-cards.card-style {
        max-width: 100%;
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 991px) {
    .card-style {
        margin: 0 auto;
    }    
}



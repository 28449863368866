.henrico-taxes-table,
.state-taxes-table {
    width: 100%;
}

/*.table, th, td {
    //border: 1px solid black;
    border-collapse: collapse;
    border: 1px solid #444;
    border-collapse: collapse;
    width: 100%;
    border-left: none;
    border-right: none;
   // border-top: none;
    !* text-align: left; *!
    padding: 3px 15px;
   // border-right: none;
}*/



.dot {
    height: 7px;
    width: 7px;
    background-color: #000;
    border-radius: 50%;
    display: inline-block;
  }

/*#vatax {
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}*/
.tableMargin{
    margin: 0rem 0.5rem 0rem 0.5rem !important;
    border-top: 1px solid #444;
}

.tableMargin1{
    margin: 0rem 0.5rem 0rem 0.5rem !important;
    border-bottom: 1px solid #444;
}
.borderBottom{
    border-bottom: 1px solid #444;
}
.subTableMargin{
    border-bottom: 1px solid #444;
}
.centerContent{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
}
.list-of-employers-wrapper {
    background: #F1F1F1;
    padding: 4rem 0;
    margin: 6rem auto;
}

.list-of-employers-container {
    max-width: 1200px;
    margin: 0 auto !important;
}

.list-of-employers-container p {
  margin-bottom: 1.25rem;
  font-weight: 900;
  line-height: 1.5;
}

.legacy-company-years {
    text-align: center;
}

.henrico-legacy-companies-wrapper {
    margin: 4rem 0;
}

.legacy-slider.row {
    margin: 4rem 0;
    background-color: #F6F6F6;
}

.legacy-slider-container.col {
    max-width: 1200px;
    margin: 0 auto;
}

.carousel-content {
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-left: 4rem;
    padding-right: 4rem;
}

@media screen and (min-width: 320px) {
    .carousel-content {
        justify-content: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding-left: 0;
        padding-right: 1rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}


.legacy-company-list {
    text-align: left;
}

ul.list-padding-left.legacy-company-list {
    padding-right: 0;
}

.major-employers-card-row.row {
    justify-content: center;
    max-width: 800px;
    margin: 0 auto;
}

.major-employers-card {
    margin-bottom: 1rem;
}

@media screen and (min-width: 769px) and (max-width: 991px) {
    .major-employers-card-row.row {
        max-width: 600px;
        margin: 0 auto;
        justify-content: flex-start;
    }
}

@media screen and (max-width: 768px) {
    .major-employers-card-row.row {
        max-width: 400px;
        margin: 0 auto;
        justify-content: flex-start;
    }
}

.legacyMapImg {
    max-width: 100%;
    padding-bottom: 40px;
}

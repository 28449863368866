.news-insights-title {
    margin-bottom: 0;
}

.row-news-insights {
    margin-bottom: 2.5rem;
    margin-top: 4rem !important;
}

.news-tile-text.card-body {
    background-color: white;
    padding-top: .5rem !important;
}

.news-card-row {
    margin-top: 1rem !important;
}
$shadowBackground: #000000;
$whiteBackground: #FFFFFF;
$white: #ffffff;

.navbar-primary {
    background-color: fade-out($shadowBackground, 0.6);
    position: absolute !important;
    z-index: 5 !important;
    width: 100% !important;
    font-weight: 900;
    font-size: smaller;
}

.navbar-primary.navbar {
    display: flex !important;
}

.navbar-light .navbar-nav .nav-link {
    color: white !important;
}

.navbar-nav {
    flex-direction: column !important;
}

.dropdown-toggle::after {
    display: none !important;
}

.dropdown {
    font-weight: 700;
}

.dropdown.nav-item {
    margin-right: 1.1rem;
}

.dropdown-item {
    font-size: .8rem;
}

.row-1,
.row-2 {
    display: flex;
}

img.header-brand-logo {
    max-width: 250px;
}

.navbar-light .navbar-toggler-icon {
    background-image: url('./images/hamburger-icon-2x.png') !important;
    height: 30px !important;
    width: 30px !important;
}

.row-2 {
    justify-content: space-between;
    margin-top: .4rem;
}

.gsc-search-box{
    width: 100% !important;
}

.gsc-input-box {
    opacity: 0.5 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.gsc-search-button-v2 {
    padding: 8px 15px !important;
}

.gsc-searchbox-container {
    width: 25rem;
    max-width: 90%;
    margin: 0 auto;
}

.gsc-searchbox-container-header {
    width: 25rem;
    max-width: 90%;
    margin: 0 auto;
    position: absolute;
    right: 2.5rem;
}

.gsc-searchbox-container-henrico-now {
    margin: 0 auto;
    width: 100%;
    max-width: 92%;
}

div#___gcse_1 {
    margin-left: 0 !important;
}

@media screen and (max-width: 1200px) {
    .gsc-searchbox-container-header {
        position: relative;
        right: 0;
    }
}

@media screen and (max-width: 1199px) {
    button.navbar-toggler {
        z-index: 5;
    }

    .navbar-nav {
        background-color: fade-out($shadowBackground, 0.1) !important;
        left: 50%;
        margin-top: .5rem;
        padding-bottom: 1rem;
        padding-top: 1rem;
        position: fixed;
        transform: translateX(-50%);
        text-align: center;
        width: 100%;
        z-index: 6;
    }

    .gsc-input-box {
        opacity: 0.5 !important;
    }

    .form-inline {
        max-width: 90%;
        margin: 0 auto;
    }

    .row-1,
    .row-2 {
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width: 576px) {
    button.navbar-toggler {
        position: absolute;
        right: 0;
    }
    img.header-brand-logo {
        max-width: 200px;
    }
}

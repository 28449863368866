.home-data-card {
    background-color: #E6E6E6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    text-align: left;
    min-height: 160px;
}

.home-data-card-wrapper {
    width: 100%;
    max-width: 170px;
    min-width: 170px;
    height: 160px;
    margin: 1rem auto;
}
@media screen and (max-width: 320px) {
    .home-data-card-wrapper {
        margin: 1rem auto 1rem 0;
    }
}

.home-data-card-body {
  max-width: 80%;
  margin: 0 .5rem;
  p {
    line-height: 1rem;

    &:nth-child(1) {
      margin-bottom: 0.5rem;
      strong { line-height: 1rem; }
    }
    &:nth-child(2) {
      font-size: 0.7rem;
      overflow-y: scroll;
      max-height: 4.0625rem;
    }
  }
}

.card-row-home-cards.cards-home.row {
    margin: 0 auto 4rem;
}

@media (min-width: 991px) and (max-width: 1200px) {
    .card-row-home-cards.cards-home.row {
        max-width: 800px;
    }
}
@media (min-width: 769px) and (max-width: 991px) {
    .card-row-home-cards.cards-home.row {
        max-width: 400px;
    }
}
@media screen and (max-width: 768px) {
    .card-row-home-cards.cards-home.row {
        max-width: 400px;
    }
    .card-column.col-xl-2.col-lg-3.col-md-6.col-sm-6.col-6 {
        padding-left: 5px;
    }
}

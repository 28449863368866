.body-copy {
    margin-bottom: 2rem;
}

.breadcrumb-nav {
    margin-top: 1rem;
}

.breadcrumb-active {
    color: #04368B;
    font-weight: 600;
}

.henrico-tomorrow-banner {
    background-color: #164fa0;
    margin-bottom: 20px;
    max-width: 624px;
}

.banner-title1 {
    text-transform: uppercase;
    text-align: center;
    color: white;
    font-size: 18px;
}

.banner-title2 {
    text-transform: lowercase;
    text-align: center;
    color: #a4ddff;
    padding-bottom: 32px;
    font-size: 34px;
}

.page-header {
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    padding-bottom: 12px;
}

.logo-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
}

.tomorrow-banner-img {
    width: 52%;
}

@media screen and (max-width: 480px) {
    .banner-title1 {
        font-size: 12px;
    }
    
    .banner-title2 {
        font-size: 24px;
    }
    .tomorrow-banner-img {
        width: 100%;
    }
  }
.rotating-banner-row {
  background-color: #e2e3e4;
  padding-top: 1.5rem;
  padding-bottom: 2rem;
}

.companies-banner {
  text-align: center;
}

.companies-banner-title {
  color: #04368b;
  font-size: 30px;
  font-weight: 900;
  margin-bottom: 1rem;
}

.companies-banner-description {
  margin-bottom: 0.5rem;
}

.slideshow {
  position: relative;
  overflow: hidden;
  height: 349px;
}

.images {
  background: url(./images-home-rotating-banner/logo-banner.png) repeat-x;
  position: absolute;
  height: 100%;
  width: 300%;
  -webkit-animation: slideshow 100s linear infinite;
  animation: slideshow 100s linear infinite;
}

@-webkit-keyframes slideshow {
  0% {
    left: 0;
  }

  100% {
    left: -200%;
  }
}

@keyframes slideshow {
  0% {
    left: 0;
  }

  100% {
    left: -200%;
  }
}

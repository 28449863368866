.button-grow-business {
  margin: 2rem 2rem 2rem 0;
}

.button-large {
  padding-left: 1rem;
  padding-right: 1rem;
}

.paragraph-header--black1 {
  color: #000000;
  margin-bottom: 0;
}
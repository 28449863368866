.post-logo img {
    margin: 0 auto;
}

.henrico-now-post-date-row.row {
    margin-top: 0;
}
.rail-images {
  img {
    width: 100%;
    margin-bottom: 1rem;
  }
}

.strong{
  font-weight: 700 !important;
}

.henriconow-two-col-wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.henriconow-two-col {
  width: 48%;
}

@media screen and (max-width: 480px) {
  .henriconow-two-col-wrapper {
    display: flex;
    flex-direction: column;
  }

  .henriconow-two-col {
    width: 100%;
  }
}

.row-40-60 {
 display: flex;
 flex-direction: row;
 gap: 0px;
 spacing: 0px;
}

.col-40 {
min-width: 40%;
 max-width: 40%;
}

.col-40 img {
  min-height: 100%;
  object-fit: cover;
}

.col-60 {
 min-width: 60%;
 max-width: 60%;
}

.incentives-text {
  padding: 24px;
  background-color: #f0f0f0;
  width: 100%;
}




@media screen and (max-width: 900px) {
  .row-40-60 {
    flex-direction: column;
   }
   
   .col-40 {
    max-width: 100%;
   }

   .col-40 img {
    max-height: 460px;
    object-fit: cover;
  }
   
   .col-60 {
    max-width: 100%;
   }
   .desktop-only {
    display: none!important;
   }
}

@media screen and (min-width: 901px) {
  .mobile-only {
    display: none!important;
  }
}
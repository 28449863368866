.social-icon {
    width: 32px;
    height: 32px;
    max-height: 100%;
    max-width: 100%;
    margin-right: 1rem;
}

.social-icons.col-lg-6.col-md-6.col-12 {
    text-align: right;
}

.social-div {
    margin-top: 4rem;
    text-align: center;
    max-width: 580px;
    margin: 4rem auto 2rem;
}

.social-search-bar-column,
.social-icons-column {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

@media screen and (max-width: 768px) {
    .social-div {
        margin-top: 2rem;
    }
    .social-icons {
        margin-bottom: 1rem;
    }
}
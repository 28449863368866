a.link--black {
    color: black !important;
}

.archive-date-row {
    margin-top: 0;
}

p.legend {
    width: 50% !important;
    border-radius: 0 !important;
    opacity: .5 !important;
    bottom: 0 !important;
}

.carousel:hover .slide .legend {
    opacity: .6;
}

p.paragraph-header--blue.carousel-property-type {
    text-align: center;
    margin-bottom: 1.5rem;
}

.carousel-root.featured-property-carousel {
    width: 350px;
    max-width: 100%;
    margin: 0 auto;
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
    opacity: 1;
}

.slider-column {
    height: 250px;
}

ul.thumbs.animated {
    padding-left: 0;
}

@media screen and (max-width: 991px) {
    .slider-column {
        height: 100%;
    }
    p.legend {
        width: 90% !important;
    }
}

.carousel .slide img {
    width: 100%;
    vertical-align: top;
    border: 0;
    height: 100%;
}
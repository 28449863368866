.post-logo {
    text-align: center;
}

.title {
    text-align: center;
}

.date {
    text-align: center;
}

.single-date-row {
    margin-top: 0;
}

strong{
    font-weight: 700 !important;
}

.single-press-release-title {
    color: #04368B;
    font-size: 1.2rem;
    font-weight: 800;
    margin: 1rem auto 0;
    text-align: center;
}

.single-press-release-headline {
    font-size: 1.2rem;
    font-weight: 800;
}

.single-press-release-contact {
    font-weight: 800;
}

.single-copy-row.row {
    margin-top: 0;
}
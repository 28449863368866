.row-members1 {
  margin-top: 3rem;
}

.row-individual-member1 {
  margin-bottom: 3rem;
  padding-left: 265px;
}

.member-title1 {
  //font-weight: 900;
  margin-bottom: 0;
}

.member-title2 {
 //padding-bottom: 10px;
  margin-bottom: 0;
}


.copy-black {
  color: #000000;
}

.card-row.ic.row {
    justify-content: space-between;
}

.international-logo {
  padding-bottom: 70px;
}

.international-image {
  max-width: 100%;
}

.international-col1 {
  padding: 0px;
}


.international-col2 {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.international-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  background-color: #f6f6f6;
}

.center-aligned {
  text-align: center;
}

.international-header1 {
  font-size: 28px;
  font-weight: 800!important;
  line-height: 1.4;
}

.international-header2 {
  font-size: 28px;
  line-height: 1.4;
  font-weight: 600!important;
}

.maxwidth540 {
  max-width: 540px;
}

@media screen and (max-width: 768) {
  .international-row {
    display: flex;
    flex-direction: column;
  }
  .international-image {
    max-width: 100%;
  }
}
.webinar-thumbnail-row.row {
  margin: 0 auto;
  max-width: 100%;
  justify-content: center;
}

.webinar-title-row.row {
  margin: 0 auto;
}

.webinar-title {
  margin: 20px auto 20px;
  text-align: center;
  font-weight: 700;
}

.webinar-container {
  margin: 2rem auto;
}

.secondary-nav-link {
    color: #626262 !important;
    text-align: center;
    font-weight: 700;
}

.secondary-nav-separator {
    margin-left: 1rem;
    margin-right: 1rem;
}

.secondary-nav-link-active {
    color: #054496 !important;
    font-weight: 800;
}

.secondary-nav {
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;
}

nav.secondary-navabar.navbar.navbar-expand-xs.navbar-light {
    margin-top: 1rem;
    justify-content: center;
}

@media screen and (max-width: 576px) {
    .secondary-nav-separator {
        display: none;
    }
    a.secondary-nav-link {
        width: 100%;
        margin-bottom: .5rem;
    }
}
